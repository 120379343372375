/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import './styles.css';

import weightMask from '../../../../utils/masks/weightMask';

export default function ProductionList({ productionParam, protheusIDsParam }) {
  function isRateioFromUser(rateioLoop) {
    const isFromUser = protheusIDsParam.map(
      (protheusID) => protheusID === rateioLoop.PRODUTOR,
    );
    console.log('isRateioFromUser | isFromUser: ', isFromUser);
    return isFromUser[0];
  }

  const rateios = (productionParam.RATEIO).filter(isRateioFromUser);
  console.log('ProductionDetail | rateios', rateios);

  function calculeTotalWeightDelivery() {
    const weightInitial = parseInt(productionParam.PESO_INICIAL);
    const weightFinal = parseInt(productionParam.PESO_FINAL);
    const totalWeightDelivery = weightInitial - weightFinal;

    return weightMask(totalWeightDelivery);
  }

  return (
    <div className="production--container">
      <div className="production--container-row">
        <p className="details--text details--text-title">
          PESO INICIAL
        </p>
        <p className="details--text details--text-title">
          PESO FINAL
        </p>
      </div>

      <div className="production--container-row">
        <p className="details--text details--text-description">
          {weightMask(productionParam.PESO_INICIAL)}
        </p>
        <p className="details--text details--text-description">
          {weightMask(productionParam.PESO_FINAL)}
        </p>
      </div>

      <div className="production--container-row">
        <p className="details--text details--text-title">
          VALOR UNITARIO
        </p>
        <p className="details--text details--text-title">
          DATA
        </p>
      </div>

      <div className="production--container-row">
        <p className="details--text details--text-description">
          {`R$ ${productionParam.VALOR_UNITARIO}`}
        </p>
        <p className="details--text details--text-description">
          {productionParam.DATA}
        </p>
      </div>

      {rateios.map((rateio) => (
        <>
          <div className="production--container-row">
            <p className="details--text details--text-title">
              PESO INDIVIDUAL
            </p>
          </div>

          <div className="production--container-row">
            <p className="details--text details--text-description">
              {weightMask(rateio.QUANTIDADE)}
            </p>
          </div>
        </>
      ))}

      <div className="production--container-row">
        <p className="details--text details--text-title">
          PESO TOTAL DA ENTREGA
        </p>
      </div>

      <div className="production--container-row">
        <p className="details--text details--text-description">
          {calculeTotalWeightDelivery()}
        </p>
      </div>
    </div>
  );
}
