/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import BenefitsTable from '../BenefitsTable';

import './styles.css';

export default function ModalNextLevel({ onAction, nextLevelParam }) {
  useEffect(() => {
    console.log('ModalNextLevel | nextLevelParam: ', nextLevelParam);
  }, [nextLevelParam]);

  function requirementsSplit(requirements) {
    console.log('requirementsSplit | requirements: ', requirements);
    try {
      const regex = /\s*;\s*/;
      const requirementsList = requirements.split(regex);
      console.log('requirementsSplit | requirementsList: ', requirementsList);
      return requirementsList;
    } catch (error) {
      console.log('requirementsSplit | error: ', error);
      return [];
    }
  }

  return (
    <div className="modal">
      <div className="form">
        <p className="form__description">
          PRÓXIMO NÍVEL
        </p>

        <div className="medal__container" aria-hidden="true">
          <img src={nextLevelParam.image} alt="newsImage" width="100%" height="180" style={{ objectFit: 'contain' }} />
          <p className="descriptionCardLevel__value">
            {(nextLevelParam.name)}
          </p>
        </div>
        <p className="benefitsCardLevel__value">
          Benefícios
        </p>

        <BenefitsTable benefitsParam={requirementsSplit(nextLevelParam.requirements)} />

        <button
          className="form__button"
          type="button"
          onClick={() => onAction()}
        >
          FECHAR
        </button>
      </div>
    </div>
  );
}
