/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import SlideBanner from './components/SlideBanner';
import SlideButtons from './components/SlideButtons';
import LoanSummary from './components/LoanSummary';
import ProductionSummary from './components/ProductionSummary';
import SummaryNotFound from './components/SummaryNotFound';
import ModalNewTermUse from './components/ModalNewTermUse';

import getCropsByYearByYear from '../../utils/getCropsByYear';
import { getToken, logout } from '../../api/auth';

import ProductionsByCropFetch from '../../api/services/ProductionAPI';
import { FinancialsFetch } from '../../api/services/FinancialAPI';
import { NewsFetch } from '../../api/services/NewsAPI';
import { NewTermsUseAvailableFetch, UpdateAcceptedTermUseFetch } from '../../api/services/TermOfUseAPI';

export default function Home({ history }) {
  const token = getToken();
  const [news, setNews] = useState([]);
  const [loanDetailSummary, setLoanDetailSummary] = useState(null);
  const [productionDetailSummary, setProductionDetailSummary] = useState(null);
  const [isAvailableNewTermsUse, setIsAvailableNewTermsUse] = useState(false);
  const [termOfUseDetails, setTermOfUseDetails] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function getLoans() {
    setIsLoad(true);
    try {
      const resultFinancials = await FinancialsFetch(token);
      console.log('getLoans | resultFinancials: ', resultFinancials);
      setIsLoad(false);
      setMessageWarning(resultFinancials.message);
      setIsSuccessMessage(resultFinancials.isSuccess);
      const loansList = resultFinancials.loans;
      setLoanDetailSummary(loansList.length >= 1 ? loansList[0] : null);
    } catch (error) {
      console.log('getLoans | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getProductionsByProduct(productId) {
    setIsLoad(true);
    console.log('getProductionsByProduct | productId: ', productId);
    try {
      const resultProductions = await ProductionsByCropFetch(token, productId);
      console.log('getProductions | resultProductions: ', resultProductions);
      setIsLoad(false);
      setMessageWarning(resultProductions.message);
      setIsSuccessMessage(resultProductions.isSuccess);
      if (resultProductions.isSuccess) {
        const { productions } = resultProductions;
        setProductionDetailSummary(productions.length >= 1 ? productions[0] : null);
      }
    } catch (error) {
      console.log('getProductionsByProduct | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getNews() {
    setIsLoad(true);
    try {
      const resultNews = await NewsFetch();
      console.log('getNews | resultNews: ', resultNews);
      setIsLoad(false);
      setMessageWarning(resultNews.message);
      setIsSuccessMessage(resultNews.isSuccess);
      if (resultNews.isSuccess) {
        const newsRecents = (resultNews.news).slice(0, 3);
        console.log('getNews | newsRecents: ', newsRecents);
        const newsRecentsWithFocus = newsRecents.map((
          newsRecent,
          indexNewsRecent,
          // listNewsRecents,
        ) => {
          const newsRecentManipule = newsRecent;
          newsRecentManipule.isHidden = (indexNewsRecent !== 0);
          console.log('getNews | newsRecentManipule: ', newsRecentManipule);
          return newsRecentManipule;
        });
        console.log('getNews | newsRecentsWithFocus: ', newsRecentsWithFocus);
        setNews(newsRecentsWithFocus);
      }
    } catch (error) {
      console.log('getNews | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getNewTermsUseAvailable() {
    setIsLoad(true);
    try {
      const resultNewTermsUseAvailable = await NewTermsUseAvailableFetch(token);
      console.log('getNewTermsUseAvailable | resultNewTermsUseAvailable: ', resultNewTermsUseAvailable);
      setIsLoad(false);
      setMessageWarning(resultNewTermsUseAvailable.message);
      setIsSuccessMessage(resultNewTermsUseAvailable.isSuccess);
      setIsAvailableNewTermsUse(resultNewTermsUseAvailable.isAvailable);
      setTermOfUseDetails(
        resultNewTermsUseAvailable.isAvailable ? resultNewTermsUseAvailable.termOfUse
          : null,
      );
    } catch (error) {
      console.log('getNewTermsUseAvailable | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getLoans(token);
    getNews();
    const cropsByYear = getCropsByYearByYear();
    getProductionsByProduct(cropsByYear[0].name);
    getNewTermsUseAvailable();
  }, [history]);

  async function sendUpdateAcceptedTermUseFetch(tokenAuth, isAcceptedTermUse) {
    console.log('sendUpdateAcceptedTermUseFetch | tokenAuth, isAcceptedTermUse: ', tokenAuth, isAcceptedTermUse);
    try {
      const resultNewTermsUseAvailable = await UpdateAcceptedTermUseFetch(
        tokenAuth,
        termOfUseDetails.id,
        isAcceptedTermUse,
      );
      console.log('getNewTermsUseAvailable | resultNewTermsUseAvailable: ', resultNewTermsUseAvailable);
      setIsLoad(false);
      setMessageWarning(resultNewTermsUseAvailable.message);
      setIsSuccessMessage(resultNewTermsUseAvailable.isSuccess);
      setIsAvailableNewTermsUse(false);
      if (!isAcceptedTermUse) {
        logout();
        navigate('/', { replace: true });
      }
    } catch (error) {
      console.log('sendUpdateAcceptedTermUseFetch | error: ', error);
      alertMessage(
        'Aviso',
        'No momento esse recurso está indisponível, tente novamente mais tarde.',
      );
    }
  }

  function renderSlides(newsCurrent) {
    return (
      <SlideBanner
        newsParam={newsCurrent}
        onAction={() => navigate(`/news/${newsCurrent.id}`, { replace: true })}
      />
    );
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Início
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {news.map((newsLoop) => renderSlides(newsLoop))}

          {news.length > 0
            ? (
              <SlideButtons
                newsParam={news}
                onAction={(newsUpdated) => setNews(newsUpdated)}
              />
            ) : null }

          {productionDetailSummary !== null
            ? (
              <ProductionSummary
                productionParam={productionDetailSummary}
                onAction={() => navigate('/production', { replace: true })}
              />
            ) : (
              <SummaryNotFound
                label="NENHUMA PRODUÇÃO REGISTRADA RECENTEMENTE"
                onAction={() => navigate('/production', { replace: true })}
              />
            )}

          {loanDetailSummary !== null
            ? (
              <LoanSummary
                loanParam={loanDetailSummary}
                onAction={() => navigate('/financial', { replace: true })}
              />
            ) : (
              <SummaryNotFound
                label="NENHUMA ANTECIPAÇÃO RECENTE, SOLICITE A SUA PRIMEIRA PARA ACOMPANHAR"
                onAction={() => navigate('/financial', { replace: true })}
              />
            )}
        </div>
      </div>
      {isAvailableNewTermsUse ? (
        <ModalNewTermUse
          onAction={(isAccepted) => sendUpdateAcceptedTermUseFetch(token, isAccepted)}
          onRead={() => { window.location.href = termOfUseDetails.terms_of_use; }}
        />
      ) : null}
    </>
  );
}
