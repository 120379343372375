/* eslint-disable no-console */
import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export async function ItemsFetch(orderOption) {
  console.log('ItemsFetch | orderOption: ', orderOption);
  const configRequest = {
    method: 'get',
    url: `/items?order=${orderOption}`,
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );
    console.log('ItemsFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      items: response.data.items,
    };
  } catch (error) {
    console.log('ItemsFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function CategoriesFetch() {
  console.log('CategoriesFetch');
  const configRequest = {
    method: 'get',
    url: '/store_category',
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );
    console.log('CategoriesFetch | response.data: ', response.data);
    const manipuleCategories = response.data;
    console.log('categoriesSuccess | manipuleCategories:', manipuleCategories);
    const removeCategory = { id: 0, name: 'sem filtro por categoria' };
    console.log('categoriesSuccess | removeCategory:', removeCategory);
    const categoriesWithChoiceRemoveCategory = [...manipuleCategories, removeCategory];
    console.log('categoriesSuccess | categoriesWithChoiceRemoveCategory:', categoriesWithChoiceRemoveCategory);

    return {
      isSuccess: true,
      message: '',
      categories: categoriesWithChoiceRemoveCategory,
    };
  } catch (error) {
    console.log('CategoriesFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function DiscountsFetch(tokenAuthorization) {
  console.log('DiscountsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/discount_coupons/discounts_by_level',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('DiscountsFetch | response.data: ', response.data);
    const manipuleDiscounts = response.data.discounts;
    console.log('discountsSuccess | manipuleDiscounts:', manipuleDiscounts);
    const removeDiscount = {
      id: 0, name: 'remover cupom de desconto', description: '', percent: 0,
    };
    console.log('discountsSuccess | removeDiscount:', removeDiscount);
    const discountsWithChoiceRemove = [...manipuleDiscounts, removeDiscount];
    console.log('discountsSuccess | discountsWithChoiceRemove:', discountsWithChoiceRemove);

    return {
      isSuccess: true,
      message: '',
      discounts: discountsWithChoiceRemove,
    };
  } catch (error) {
    console.log('DiscountsFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      discounts: [],
    };
  }
}

export async function DiscountDetailByNameFetch(
  tokenAuthorization,
  discountName,
) {
  console.log('DiscountDetailByNameFetch | discountName: ', discountName);
  const configRequest = {
    method: 'get',
    url: `/discount_coupons/search_for_discount?name=${discountName}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('DiscountDetailByNameFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: `cupom ${response.data.discount.name} está disponível`,
      discount: response.data.discount,
    };
  } catch (error) {
    console.log('DiscountDetailByNameFetch | error: ', error.message);
    let message;
    if (error.message === 'Request failed with status code 401') {
      message = 'cupom inserido não está disponível para o seu nível atual.';
    } else if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
      discount: null,
    };
  }
}

export async function PaymentsFetch() {
  console.log('PaymentsFetch');
  const configRequest = {
    method: 'get',
    url: '/form_payment',
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );
    console.log('PaymentsFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      payments: response.data,
    };
  } catch (error) {
    console.log('PaymentsFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      payments: null,
    };
  }
}

export async function ItemsByNameOrCategoryFetch(itemName, categoryId, orderOption) {
  console.log('ItemsByNameOrCategoryFetch | itemName, categoryId, orderOption: ', itemName, categoryId, orderOption);
  let urlQuery = '';
  if (itemName !== '') {
    urlQuery += `name=${itemName}`;
  }
  if (urlQuery.length > 0 && categoryId !== 0) {
    urlQuery += `&category_id=${categoryId}`;
  }

  if (urlQuery.length === 0 && categoryId !== 0) {
    urlQuery += `category_id=${categoryId}`;
  }

  const configRequest = {
    method: 'get',
    url: `/items/search_for_items?${urlQuery}&order=${orderOption}`,
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );
    console.log('ItemsByNameOrCategoryFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      items: response.data.items,
    };
  } catch (error) {
    console.log('ItemsByNameOrCategoryFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function ItemByIdFetch(itemId) {
  console.log('ItemByIdFetch | itemId: ', itemId);
  const configRequest = {
    method: 'get',
    url: `/items/item_by_id?item_id=${itemId}`,
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );

    console.log('ItemByIdFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      item: response.data.item,
    };
  } catch (error) {
    console.log('ItemByIdFetch | error: ', error);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
      item: null,
    };
  }
}

export async function DemandsByUserIdFetch(tokenAuthorization) {
  console.log('DemandsByUserIdFetch | tokenAuthorization : ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/demands/demands_by_user',
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('DemandsByUserIdFetch | response.data.demands: ', response.data.demands);
    return {
      isSuccess: true,
      message: '',
      demands: response.data.demands,
    };
  } catch (error) {
    console.log('DemandsByUserIdFetch | error: ', error);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
    };
  }
}

export async function DemandByIdFetch(tokenAuthorization, demandId) {
  console.log('DemandByIdFetch | tokenAuthorization, demandId: ', tokenAuthorization, demandId);
  const configRequest = {
    method: 'get',
    url: `/demands/demand_by_id?demand_id=${demandId}`,
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('DemandByIdFetch | response.data.demand: ', response.data.demand);
    return {
      isSuccess: true,
      message: '',
      demand: response.data.demand,
    };
  } catch (error) {
    console.log('DemandByIdFetch | error: ', error);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
    };
  }
}

export async function StagesByDemandIdFetch(tokenAuthorization, demandId) {
  console.log('StagesByDemandIdFetch | tokenAuthorization, demandId: ', tokenAuthorization, demandId);
  const configRequest = {
    method: 'get',
    url: `/stages/stages_by_demand?demand_id=${demandId}`,
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('StagesByDemandIdFetch | response.data.stages: ', response.data.stages);
    return {
      isSuccess: true,
      message: '',
      stages: response.data.stages,
    };
  } catch (error) {
    console.log('StagesDemandsFetch | error: ', error);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
    };
  }
}

export async function DemandCreateFetch(
  tokenAuthorization,
  itemId,
  value,
  quantity,
  observation,
  formPaymentId,
  discountId,
) {
  console.log(
    'DemandCreateFetch | tokenAuthorization, itemId, quantity, value, paymentTypeId: ',
    tokenAuthorization,
    itemId,
    value,
    quantity,
    observation,
    formPaymentId,
    discountId,
  );
  const configRequest = {
    method: 'post',
    url: '/demands/create_demand',
    body: {
      item_id: itemId,
      value,
      quantity,
      observation,
      payment_type_id: formPaymentId,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  if (discountId) {
    configRequest.body.discount_id = discountId;
  }
  console.log('DemandCreateFetch | body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('DemandCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('DemandCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        {
          ...configRequest.headers.headers,
          ...configRequest.body,
        },
      );

      return {
        isSuccess: false,
        message,
      };
    }
  }
}

export async function RatingCreateFetch(
  tokenAuthorization,
  demandId,
  evaluation,
  observation,
) {
  console.log(
    'RatingCreateFetch | tokenAuthorization, value: ',
    tokenAuthorization,
    demandId,
    evaluation,
    observation,
  );
  const configRequest = {
    method: 'post',
    url: '/rating_demand/evaluate_demand',
    body: {
      demand_id: demandId,
      evaluation,
      observation,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };
  console.log('RatingCreateFetch | configRequest.body:', configRequest.body);
  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('RatingCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('RatingCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401') {
      message = error.response.data.message;
    } else {
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function RatingByDemandFetch(tokenAuthorization, demandId) {
  console.log('RatingByDemandFetch | tokenAuthorization, demandId: ', tokenAuthorization, demandId);
  const configRequest = {
    method: 'get',
    url: `/rating_demand/evaluate_by_demand?demand_id=${demandId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('RatingByDemandFetch | response.data: ', response.data);
    const isAlreadyRating = response.data.already_evaluate;

    return {
      isSuccess: true,
      message: '',
      rating: isAlreadyRating ? response.data.evaluate : null,
      isAlreadyRating,
    };
  } catch (error) {
    console.log('RatingByDemandFetch | error: ', error.message);
    let message;
    let isSuccess;
    if (error.message === 'Request failed with status code 404') {
      isSuccess = true;
      message = '';
    } else {
      createExceptionSentry(error, configRequest.method, configRequest.url);
      isSuccess = false;
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess,
      message,
      rating: null,
      isAlreadyRating: false,
    };
  }
}
