/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import './styles.css';

import weightMask from '../../../../utils/masks/weightMask';

export default function ProductionTotalCrop({ totalWeightDelivery }) {
  return (
    <div className="production--container">
      <div className="production--container-row">
        <p className="details--text details--text-title">
          PESO TOTAL DE ENTREGA DA SAFRA
        </p>
      </div>

      <div className="production--container-row">
        <p className="details--text details--text-description">
          {weightMask(totalWeightDelivery)}
        </p>
      </div>
    </div>
  );
}
