/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import { addMoneyMask } from '../../utils/masks/manipulateMoneyMask';

import {
  ItemByIdFetch,
  DiscountsFetch,
  PaymentsFetch,
  DiscountDetailByNameFetch,
  DemandCreateFetch,
} from '../../api/services/StoreAPI';

export default function StoreDetail({ history }) {
  const token = getToken();
  const [item, setItem] = useState(null);
  const [payments, setPayments] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState({ id: 0, name: '' });
  const [discountSelected, setDiscountSelected] = useState({
    id: 0, name: '', description: '', percent: 0,
  });
  const [observation, setObservation] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [cartValueDiscount, setCartValueDiscount] = useState(0);
  const [cartValueTotal, setCartValueTotal] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { itemId } = useParams();
  const navigate = useNavigate();
  const amountDiscountsAvailable = (discounts.length) - 1;

  function discountFullAmountWithCoupon(coupon, amountNew, itemPrice) {
    console.log('discountFullAmountWithCoupon | coupon: ', coupon);
    setDiscountSelected(coupon);
    const itemTotalWithQuantity = itemPrice * amountNew;
    console.log('discountFullAmountWithCoupon | amountNew: ', amountNew);
    console.log('discountFullAmountWithCoupon | itemTotalWithQuantity: ', itemTotalWithQuantity);
    const discountFromItem = (itemTotalWithQuantity * coupon.percent) / 100;
    console.log('discountFullAmountWithCoupon | discountFromItem: ', discountFromItem);
    setCartValueDiscount(discountFromItem);
    const itemTotal = itemTotalWithQuantity - discountFromItem;
    console.log('discountFullAmountWithCoupon | itemTotal: ', itemTotal);
    setCartValueTotal(itemTotal);
  }

  function updateQuantityAndTotalValue(amount) {
    setQuantity(amount);
    if (discountSelected.id !== 0) {
      discountFullAmountWithCoupon(discountSelected, amount, item.price);
    } else {
      const itemTotalWithQuantity = item.price * amount;
      console.log('updateQuantityAndTotalValue | amount, itemTotalWithQuantity: ', amount, itemTotalWithQuantity);
      setCartValueTotal(itemTotalWithQuantity);
    }
  }

  async function getDiscounts(itemParam) {
    console.log('getDiscounts');
    try {
      const resultDiscounts = await DiscountsFetch(token);
      console.log('getDiscounts | resultDiscounts: ', resultDiscounts);
      setIsLoad(false);
      setMessageWarning(resultDiscounts.message);
      setIsSuccessMessage(resultDiscounts.isSuccess);
      setDiscounts(resultDiscounts.discounts);
      if ((resultDiscounts.discounts).length >= 1) {
        setDiscountSelected(resultDiscounts.discounts[0]);
        discountFullAmountWithCoupon(resultDiscounts.discounts[0], quantity, itemParam.price);
      }
    } catch (error) {
      console.log('getDiscounts | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getItemById() {
    setIsLoad(true);
    try {
      const resultItemById = await ItemByIdFetch(itemId);
      console.log('getItemById | resultItemById: ', resultItemById);
      setIsLoad(false);
      setMessageWarning(resultItemById.message);
      setIsSuccessMessage(resultItemById.isSuccess);
      setItem(resultItemById.item);
      if (resultItemById.item !== null) {
        setCartValueTotal(resultItemById.item.price);
        getDiscounts(resultItemById.item);
      }
    } catch (error) {
      console.log('getItemById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getPayments() {
    console.log('getPayments');
    try {
      const resultPayments = await PaymentsFetch();
      console.log('getPayments | resultPayments: ', resultPayments);
      setIsLoad(false);
      setMessageWarning(resultPayments.message);
      setIsSuccessMessage(resultPayments.isSuccess);
      setPayments(resultPayments.payments);
      if ((resultPayments.payments).length >= 1) {
        setPaymentSelected(resultPayments.payments[0]);
      }
    } catch (error) {
      console.log('getPayments | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function sendDemandCreate(tokenAuth) {
    console.log(
      'sendDemandCreate | tokenAuth, itemId, cartValueTotal, quantity, observation, paymentSelected.id, discountSelected.id: ',
      tokenAuth,
      itemId,
      cartValueTotal,
      quantity,
      observation,
      paymentSelected.id,
      discountSelected.id,
    );
    try {
      if (paymentSelected.id === 0) {
        setMessageWarning('Selecione uma forma de pagamento');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else {
        const resultDemandCreate = await DemandCreateFetch(
          tokenAuth,
          itemId,
          cartValueTotal,
          quantity,
          observation,
          paymentSelected.id,
          discountSelected.id,
        );
        console.log('getPayments | resultDemandCreate: ', resultDemandCreate);
        setIsLoad(false);
        setMessageWarning(resultDemandCreate.message);
        setIsSuccessMessage(resultDemandCreate.isSuccess);
      }
    } catch (error) {
      console.log('sendDemandCreate | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  function refreshItem() {
    getItemById();
    getPayments();
  }

  useEffect(() => {
    console.log('useFocusEffect | useEffect | focused');
    refreshItem();
    // Do something when the screen is focused

    return () => {
      console.log('useFocusEffect | useEffect | unfocused');
      setDiscountSelected({
        id: 0, name: '', description: '', percent: 0,
      });
      setObservation('');
      setDiscountCode('');
      setQuantity(1);
      // Do something when the screen is unfocused
      // Useful for cleanup functions
    };
  }, [history]);

  async function getDiscountDetailByName() {
    console.log('getDiscountDetailByName');
    try {
      const resultDiscountDetailByName = await DiscountDetailByNameFetch(token, discountCode);
      console.log('getDiscountDetailByName | resultDiscountDetailByName: ', resultDiscountDetailByName);
      setIsLoad(false);
      if (resultDiscountDetailByName.discount !== null) {
        console.log('DiscountDetailByNameFetch | discounts: ', discounts);
        const discountsNew = [...(discounts), resultDiscountDetailByName.discount];
        console.log('DiscountDetailByNameFetch | discountsNew: ', discountsNew);
        const discountAlreadyExists = discounts.find(
          (discountLoop) => discountLoop.id === resultDiscountDetailByName.discount.id,
        );
        if (discountAlreadyExists === undefined) {
          setDiscounts(discountsNew);
          setDiscountSelected(resultDiscountDetailByName.discount);
          discountFullAmountWithCoupon(resultDiscountDetailByName.discount, quantity, item.price);
        }
      }
    } catch (error) {
      console.log('getDiscountDetailByName | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes do Item
          </p>

          {item !== null ? (
            <>
              <img
                src={item.image}
                alt="LogoImage"
                className="banner_image"
              />
              <div
                className="details--container"
                aria-hidden="true"
              >
                <p className="details--text details--text-title">
                  Nome
                </p>
                <p className="details--text details--text-description">
                  {item.name}
                </p>

                <p className="details--text details--text-title">
                  Descrição
                </p>
                <p className="details--text details--text-description">
                  {item.description}
                </p>

                <p className="details--text details--text-title">
                  Tipo
                </p>
                <p className="details--text details--text-description">
                  {item.type}
                </p>

                <p className="details--text details--text-title">
                  Preço
                </p>
                <p className="details--text details--text-description">
                  {addMoneyMask(item.price)}
                </p>
              </div>

              <div className="label__container">
                <p className="form__label">
                  Observação
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui a observação"
                value={observation}
                onChange={(event) => setObservation(event.target.value)}
              />

              <div className="label__container">
                <p className="form__label">
                  Forma de Pagamento *
                </p>
              </div>
              <select
                className="form__select"
                value={paymentSelected.name}
                onChange={(event) => {
                  console.log(event.target.value);
                  function isPayment(payment) {
                    return payment.name === event.target.value;
                  }
                  const paymentFound = payments.find(isPayment);
                  console.log('paymentFound: ', event.target.value);
                  setPaymentSelected(paymentFound);
                }}
              >
                {
                  payments.map((payment) => (
                    <option value={payment.name} key={payment.name}>{payment.name}</option>
                  ))
                }
              </select>

              <div className="label__container">
                <p className="form__label">
                  Cupom de Desconto
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui o seu cupom de desconto"
                value={discountCode}
                onChange={(event) => setDiscountCode(event.target.value)}
              />

              <button
                className="form__button"
                type="button"
                onClick={(event) => getDiscountDetailByName(event)}
              >
                Adicionar Cupom
              </button>

              <p className="form__label">
                {`${amountDiscountsAvailable} cupons disponíveis para você`}
              </p>

              <select
                className="form__select"
                value={discountSelected.name}
                onChange={(event) => {
                  console.log(event.target.value);
                  function isDiscount(dicount) {
                    return dicount.name === event.target.value;
                  }
                  const dicountFound = discounts.find(isDiscount);
                  console.log('dicountFound: ', event.target.value);
                  setDiscountSelected(dicountFound);
                  discountFullAmountWithCoupon(dicountFound, quantity, item.price);
                }}
              >
                {
                  discounts.map((discount) => (
                    <option value={discount.name} key={discount.name}>{discount.name}</option>
                  ))
                }
              </select>

              <div className="label__container">
                <p className="form__label">
                  Quantidade *
                </p>
              </div>
              <input
                className="form__input"
                type="number"
                placeholder="digite o nome do produto"
                value={quantity}
                onChange={(event) => {
                  if (event.target.value >= 1) {
                    updateQuantityAndTotalValue(event.target.value);
                  } else {
                    console.log('no change quantity');
                  }
                }}
              />

              <div
                className="details--container"
                aria-hidden="true"
              >
                <p className="details--text details--text-title">
                  {`${quantity} x ${addMoneyMask(item.price)}:`}
                </p>
                <p className="details--text details--text-description">
                  {`${addMoneyMask(quantity * item.price)}`}
                </p>
                {discountSelected.id !== 0
                  ? (
                    <>
                      <p className="details--text details--text-title">
                        {`Desconto (${discountSelected.percent}%):`}
                      </p>
                      <p className="details--text details--text-description">
                        {addMoneyMask(cartValueDiscount)}
                      </p>
                      <p className="details--text details--text-description">
                        {`Descrição do Cupom: ${discountSelected.description}`}
                      </p>
                    </>
                  ) : null}

                <p className="details--text details--text-title">
                  Total:
                </p>
                <p className="details--text details--text-description">
                  {addMoneyMask(cartValueTotal)}
                </p>
              </div>

              {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

              {isSuccessMessage && messageWarning
                ? (
                  <button
                    className="form__button"
                    type="button"
                    onClick={() => navigate('/demand', { replace: true })}
                  >
                    Ir para pedidos
                  </button>
                ) : (
                  <button
                    className="form__button"
                    type="button"
                    onClick={() => sendDemandCreate(token)}
                  >
                    {`SOLICITAR ${(item.type).toUpperCase()}`}
                  </button>
                )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
