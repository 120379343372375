/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import { addCpfCnpjMask, removeCpfOrCnpjMask } from '../../utils/masks/manipulateCpfOrCnpjMask';
import removeLetter from '../../utils/masks/removeLetter';
import removeSpecial from '../../utils/masks/removeSpecial';
import removeNumberAndSpecial from '../../utils/masks/removeNumber';

import {
  BankAccountFetch,
  BankAccountCreateFetch,
  BankAccountUpdateFetch,
  BankAccountCheckAllowFetch,
} from '../../api/services/BankAccountAPI';

const banks = require('../../utils/resource/banks.json');

export default function BankAccount({ history }) {
  const token = getToken();
  const typesAccounts = [
    { name: 'poupança' },
    { name: 'conta-corrente' },
  ];

  const [typeAccountSelected, setTypeAccountSelected] = useState(typesAccounts[0]);
  const [bankNumber, setBankNumber] = useState(banks[0].number);
  const [bankName, setBankName] = useState(banks[0].bank);
  const [agencyNumber, setAgencyNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [holderName, setHolderName] = useState('');
  const [holderCpfCnpj, setHolderCpfCnpj] = useState('');
  const [pixKey, setPixKey] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isNotFoundBankAccount, setIsNotFoundBankAccount] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isAllowedEditInput, setIsAllowedEditInput] = useState(false);

  useEffect(() => {
    async function getBankAccount() {
      setIsLoad(true);
      try {
        const resultBankAccount = await BankAccountFetch(token);
        console.log('getBankAccount | resultBankAccount: ', resultBankAccount);
        setIsLoad(false);
        setMessageWarning(resultBankAccount.message);
        setIsSuccessMessage(resultBankAccount.isSuccess);
        if (resultBankAccount.isSuccess) {
          setBankNumber(resultBankAccount.bankAccount.bank_number);
          setBankName(resultBankAccount.bankAccount.bank_name);
          setAgencyNumber(resultBankAccount.bankAccount.agency);
          setAccountNumber(resultBankAccount.bankAccount.account);
          setTypeAccountSelected(resultBankAccount.bankAccount.account_type);
          setHolderName(resultBankAccount.bankAccount.holder_name);
          setHolderCpfCnpj(resultBankAccount.bankAccount.holder_cpf_cnpj);
          setPixKey(resultBankAccount.bankAccount.pix);
        }
        if (resultBankAccount.message === 'usuário não possui conta bancária') {
          setIsNotFoundBankAccount(true);
        }
      } catch (error) {
        console.log('getBankAccount | error: ', error);
        setIsSuccessMessage(false);
        setIsLoad(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      }
    }

    async function geBankAccountCheckAllow() {
      setIsLoad(true);
      try {
        const resulBankAccountCheckAllow = await BankAccountCheckAllowFetch(token);
        console.log('geBankAccountCheckAllow | resulBankAccountCheckAllow: ', resulBankAccountCheckAllow);
        setIsLoad(false);
        setMessageWarning(resulBankAccountCheckAllow.message);
        setIsSuccessMessage(resulBankAccountCheckAllow.isSuccess);
        setIsAllowedEditInput(resulBankAccountCheckAllow.isAllowed);
      } catch (error) {
        console.log('geBankAccountCheckAllow | error: ', error);
        setIsSuccessMessage(false);
        setIsLoad(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      }
    }

    getBankAccount();
    geBankAccountCheckAllow();
  }, [history, token]);

  function changeMaskCpfCnpj(textValue) {
    console.log('onChangeText | textValue: ', textValue);
    const textClean = removeCpfOrCnpjMask(textValue);
    console.log('onChangeText | textClean: ', textClean);
    const textCpfCnpj = addCpfCnpjMask(textClean);
    console.log('onChangeText | textCpfCnpj: ', textCpfCnpj);
    setHolderCpfCnpj(textCpfCnpj);
  }

  async function sendBankAccountCreate(event) {
    event.preventDefault();
    setIsLoad(true);
    if (!agencyNumber) {
      setMessageWarning('Preencha o campo número da agência');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!accountNumber) {
      setMessageWarning('Preencha o campo número da conta');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!holderName) {
      setMessageWarning('Preencha o campo CPF / CNPJ');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!holderCpfCnpj) {
      setMessageWarning('Preencha o campo CPF / CNPJ do titular');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!pixKey) {
      setMessageWarning('Preencha o campo Pix');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else {
      try {
        const resultBankAccountCreate = await BankAccountCreateFetch(
          token,
          bankNumber,
          bankName,
          agencyNumber,
          accountNumber,
          typeAccountSelected,
          holderName,
          holderCpfCnpj,
          pixKey,
        );
        console.log('sendBankAccountCreate | resultBankAccountCreate', resultBankAccountCreate);
        setIsLoad(false);
        setIsSuccessMessage(resultBankAccountCreate.isSuccess);
        setMessageWarning(resultBankAccountCreate.message);
      } catch (error) {
        console.log('sendBankAccountCreate | error', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }
  }

  async function sendBankAccountUpdate(event) {
    event.preventDefault();
    setIsLoad(true);
    if (!agencyNumber) {
      setMessageWarning('Preencha o campo número da agência');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!accountNumber) {
      setMessageWarning('Preencha o campo número da conta');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!holderName) {
      setMessageWarning('Preencha o campo nome completo do titular');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!holderCpfCnpj) {
      setMessageWarning('Preencha o campo CPF / CNPJ do titular');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else {
      try {
        const resultBankAccountUpdate = await BankAccountUpdateFetch(
          token,
          bankNumber,
          bankName,
          agencyNumber,
          accountNumber,
          typeAccountSelected,
          holderName,
          holderCpfCnpj,
          pixKey,
        );
        console.log('sendBankAccountUpdate | resultBankAccountUpdate', resultBankAccountUpdate);
        setIsLoad(false);
        setIsSuccessMessage(resultBankAccountUpdate.isSuccess);
        setMessageWarning(resultBankAccountUpdate.message);
      } catch (error) {
        console.log('sendBankAccountUpdate | error', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Informações Bancárias
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <div className="label__container">
            <p className="form__label">
              Número do Banco *
            </p>
          </div>

          <select
            className="form__select"
            value={bankNumber}
            onChange={(event) => {
              console.log(event.target.value);
              function isBank(bank) {
                return bank.number === event.target.value;
              }
              const bankFound = banks.find(isBank);
              setBankNumber(event.target.value);
              setBankName(bankFound.bank);
            }}
            disabled={!isAllowedEditInput}
          >
            {
              banks.map((bank) => (
                <option value={bank.number} key={bank.number}>{bank.number}</option>
              ))
            }
          </select>

          <div className="label__container">
            <p className="form__label">
              Nome do Banco *
            </p>
          </div>

          <select
            className="form__select"
            value={bankName}
            onChange={(event) => {
              function isBank(bank) {
                return bank.bank === event.target.value;
              }
              const bankFound = banks.find(isBank);
              console.log(event.target.value);
              setBankName(event.target.value);
              setBankNumber(bankFound.number);
            }}
            disabled={!isAllowedEditInput}
          >
            {
              banks.map((bank) => (
                <option value={bank.bank} key={bank.bank}>{bank.bank}</option>
              ))
            }
          </select>

          <div className="label__container">
            <p className="form__label">
              Número da Agência * (
              insira o número da agência e dígito juntos, sendo o último número o dígito da agência)
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui seu número da agência"
            value={agencyNumber}
            maxLength={4}
            onChange={(event) => {
              const agencyNumberWithoutLetter = removeLetter(event.target.value);
              console.log('onChange | agencyNumber | agencyNumberWithoutLetter', agencyNumberWithoutLetter);
              const agencyNumberWithoutSpecial = removeSpecial(agencyNumberWithoutLetter);
              console.log('onChange | agencyNumber | agencyNumberWithoutSpecial', agencyNumberWithoutSpecial);
              setAgencyNumber(agencyNumberWithoutSpecial);
            }}
            disabled={!isAllowedEditInput}
          />

          <div className="label__container">
            <p className="form__label">
              Número da Conta * (
              insira o número da conta e dígito juntos, sendo o último número o dígito da conta)
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui o número da conta"
            value={accountNumber}
            maxLength={12}
            onChange={(event) => {
              const accountNumberWithoutLetter = removeLetter(event.target.value);
              console.log('onChange | accountNumber | accountNumberWithoutLetter', accountNumberWithoutLetter);
              const accountNumberWithoutSpecial = removeSpecial(accountNumberWithoutLetter);
              console.log('onChange | accountNumber | accountNumberWithoutSpecial', accountNumberWithoutSpecial);
              setAccountNumber(accountNumberWithoutSpecial);
            }}
            disabled={!isAllowedEditInput}
          />

          <div className="label__container">
            <p className="form__label">
              Tipo da Conta *
            </p>
          </div>

          <select
            className="form__select"
            value={typeAccountSelected}
            onChange={(event) => {
              console.log(event.target.value);
              setTypeAccountSelected(event.target.value);
            }}
            disabled={!isAllowedEditInput}
          >
            {
              typesAccounts.map((typeAccount) => (
                <option value={typeAccount.name} key={typeAccount.name}>{typeAccount.name}</option>
              ))
            }
          </select>

          <div className="label__container">
            <p className="form__label">
              Nome Completo do Titular *
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui o nome do titular"
            value={holderName}
            onChange={(event) => {
              const holderNameWithoutNamber = removeNumberAndSpecial(event.target.value);
              console.log('onChange | holderName | holderNameWithoutNumber', holderNameWithoutNamber);
              const holderNameWithoutSpecialAndNamber = removeSpecial(holderNameWithoutNamber);
              console.log('onChange | accountNumber | accountNumberWithoutSpecial', holderNameWithoutSpecialAndNamber);
              setHolderName(holderNameWithoutSpecialAndNamber);
            }}
            disabled={!isAllowedEditInput}
          />

          <div className="label__container">
            <p className="form__label">
              CPF / CNPJ do Titular *
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui o CPF/CNPJ do titular"
            value={holderCpfCnpj}
            maxLength={18}
            onChange={(event) => changeMaskCpfCnpj(event.target.value)}
            disabled={!isAllowedEditInput}
          />

          <div className="label__container">
            <p className="form__label">
              Pix
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui o Pix"
            value={pixKey}
            maxLength={70}
            onChange={(event) => setPixKey(event.target.value)}
            disabled={!isAllowedEditInput}
          />
          {isAllowedEditInput
            ? (
              <button
                className="form__button"
                type="button"
                onClick={(event) => (isNotFoundBankAccount
                  ? sendBankAccountCreate(event) : sendBankAccountUpdate(event))}
              >
                {isNotFoundBankAccount ? 'Registrar' : 'Alterar'}
              </button>
            ) : null }

        </div>
      </div>
    </>
  );
}
