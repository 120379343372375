/* eslint-disable radix */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import ProductionList from './components/ProductionList';
import ProductionTotalCrop from './components/ProductionTotalCrop';
import ButtonsPagination from './components/ButtonsPagination';

import getCropsByYearByYear from '../../utils/getCropsByYear';
import { getToken } from '../../api/auth';

import ProductionsByCropFetch from '../../api/services/ProductionAPI';
import { PropertiesFetch } from '../../api/services/PropertyAPI';

export default function Production({ history }) {
  const token = getToken();

  const [properties, setProperties] = useState([]);
  const [crops, setCrops] = useState([]);
  const [cropSelected, setCropSelected] = useState('');
  const [productions, setProductions] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const amountItensByPage = 5;
  const totalPagesNumber = Math.ceil((productions.length) / amountItensByPage);
  const indexSliceFinal = (currentPageNumber * amountItensByPage);
  const indexSliceInitial = indexSliceFinal - amountItensByPage;
  const itensFromPage = productions.slice(indexSliceInitial, indexSliceFinal);

  async function getProductionsByProduct(productId) {
    setIsLoad(true);
    console.log('getProductionsByProduct | productId: ', productId);
    try {
      const resultProductions = await ProductionsByCropFetch(token, productId);
      console.log('getProductions | resultProductions: ', resultProductions);
      setIsLoad(false);
      setMessageWarning(resultProductions.message);
      setIsSuccessMessage(resultProductions.isSuccess);
      setProductions(resultProductions.productions);
    } catch (error) {
      console.log('getProductionsByProduct | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getProperties() {
    setIsLoad(true);
    try {
      const resultProperties = await PropertiesFetch(token);
      console.log('getProperties | resultProperties: ', resultProperties);
      setIsLoad(false);
      setMessageWarning(resultProperties.message);
      setIsSuccessMessage(resultProperties.isSuccess);
      if (resultProperties.isSuccess) {
        setProperties(resultProperties.properties);
      }
    } catch (error) {
      console.log('getProperties | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    const cropsByYear = getCropsByYearByYear();
    setCropSelected(cropsByYear[0].name);
    setCrops(cropsByYear);

    getProductionsByProduct(cropsByYear[0].name);
    getProperties();
  }, [history]);

  function calculeTotalWeightDelivery(productLoop) {
    const weightInitial = parseInt(productLoop.PESO_INICIAL);
    const weightFinal = parseInt(productLoop.PESO_FINAL);
    const totalWeightDelivery = weightInitial - weightFinal;

    return totalWeightDelivery;
  }

  function calculeTotalWeightDeliveryByCrop() {
    const totalWeightDeliveryByProduction = productions.map(calculeTotalWeightDelivery);
    console.log('calculeTotalWeightDeliveryByCrop | totalWeightDeliveryByProduction: ', totalWeightDeliveryByProduction);

    const totalWeightDeliveryByCrop = totalWeightDeliveryByProduction.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual,
      0,
    );
    console.log('calculeTotalWeightDeliveryByCrop | totalWeightDeliveryByCrop: ', totalWeightDeliveryByCrop);

    const totalWeightDeliveryByCropToString = totalWeightDeliveryByCrop.toString();

    return totalWeightDeliveryByCropToString;
  }

  function filterPropertiesProteusIDs() {
    const propertiesProtheusIds = properties.map((property) => property.protheus_id);
    console.log('filterPropertiesProteusIDs  | propertiesProtheusIds: ', propertiesProtheusIds);

    return propertiesProtheusIds;
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Produção
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <select
            className="form__select"
            value={cropSelected}
            onChange={(event) => {
              console.log(event.target.value);
              setCropSelected(event.target.value);
              getProductionsByProduct(event.target.value);
              setCurrentPageNumber(1);
            }}
          >
            {
              crops.map((crop) => (
                <option value={crop.name} key={crop.name}>{crop.name}</option>
              ))
            }
          </select>

          {productions.length === 0
            ? (
              <p className="form__message">Não há registro de produção.</p>
            ) : null }

          {productions.length >= 1
            ? (
              <ProductionTotalCrop totalWeightDelivery={calculeTotalWeightDeliveryByCrop()} />
            ) : null }

          {
            itensFromPage.map((production) => (
              <ProductionList
                productionParam={production}
                protheusIDsParam={filterPropertiesProteusIDs()}
              />
            ))
          }

          {totalPagesNumber > 1
            ? (
              <ButtonsPagination
                currentPage={currentPageNumber}
                totalPages={totalPagesNumber}
                onChangeCurrentPage={
                  (updatedCurrentPage) => setCurrentPageNumber(updatedCurrentPage)
                }
              />
            ) : null}
        </div>
      </div>
    </>
  );
}
