/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarWithHourAndMinutesMask from '../../utils/masks/calendarWithHourAndMinutesMask';
import { addMoneyMask } from '../../utils/masks/manipulateMoneyMask';

import { DemandsByUserIdFetch } from '../../api/services/StoreAPI';

export default function Solicitation({ history }) {
  const token = getToken();

  const [demands, setDemands] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getDemands() {
      setIsLoad(true);
      try {
        const resultDemands = await DemandsByUserIdFetch(token);
        console.log('getDemands | resultDemands: ', resultDemands);
        setIsLoad(false);
        setMessageWarning(resultDemands.message);
        setIsSuccessMessage(resultDemands.isSuccess);
        if (resultDemands.isSuccess) {
          setDemands(resultDemands.demands);
        }
      } catch (error) {
        console.log('getDemands | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getDemands();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Histórico de Pedidos
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <button
            className="form__button"
            type="button"
            onClick={() => navigate('/store', { replace: true })}
          >
            Criar Pedido
          </button>

          {demands.map((demand) => (
            <div
              className="detail__container"
              onClick={() => navigate(`/demand/${demand.id}`, { replace: true })}
              aria-hidden="true"
            >
              <p className="detail__value">
                {`${(demand.item.type).toUpperCase()} #${demand.id}`}
              </p>
              <p className="detail__value">
                {(demand.status).toUpperCase()}
              </p>
              <p className="detail__value">
                {(demand.item.name).toUpperCase()}
              </p>
              <p className="detail__value">
                {addMoneyMask(demand.value)}
              </p>
              <p className="detail__value">
                {(calendarWithHourAndMinutesMask(demand.created)).toUpperCase()}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
