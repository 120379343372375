/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import './styles.css';

export default function ButtonsPagination({
  currentPage,
  totalPages,
  onChangeCurrentPage,
}) {
  function nextPage() {
    if (currentPage >= 1 && currentPage < totalPages) {
      const valueCurrentPage = currentPage + 1;
      onChangeCurrentPage(valueCurrentPage);
    }
  }

  function backPage() {
    if (currentPage > 1) {
      const valueCurrentPage = currentPage - 1;
      onChangeCurrentPage(valueCurrentPage);
    }
  }

  return (
    <ul className="pagination--list">
      <li className="pagination--list-topics">
        <button
          className="pagination--button"
          type="button"
          onClick={() => backPage()}
        >
          Anterior
        </button>

      </li>
      <li className="pagination--list-topics">
        <div className="pagination--container-quantity">
          {`${currentPage} / ${totalPages}`}
        </div>
      </li>
      <li className="pagination--list-topics">
        <button
          className="pagination--button"
          type="button"
          onClick={() => nextPage()}
        >
          Próxima
        </button>
      </li>
    </ul>
  );
}
