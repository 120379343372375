/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-script-url */
/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './styles.css';

import { logout, getToken } from '../../api/auth';

import { BankAccountCheckAllowFetch } from '../../api/services/BankAccountAPI';
import { LoanCheckAllowFetch } from '../../api/services/FinancialAPI';

const { version } = require('../../../package.json');

function NavBar({ navigation }) {
  const token = getToken();

  const [isAllowedEditInput, setIsAllowedEditInput] = useState(false);
  const [isAllowedCreateLoan, setIsAllowedCreateLoan] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function geBankAccountCheckAllow() {
      try {
        const resulBankAccountCheckAllow = await BankAccountCheckAllowFetch(token);
        console.log('geBankAccountCheckAllow | resulBankAccountCheckAllow: ', resulBankAccountCheckAllow);
        setIsAllowedEditInput(resulBankAccountCheckAllow.isAllowed);
      } catch (error) {
        console.log('geBankAccountCheckAllow | error: ', error);
      }
    }

    async function geLoanCheckAllowFetch() {
      try {
        const resulLoanCheckAllowFetch = await LoanCheckAllowFetch(token);
        console.log('geLoanCheckAllowFetch | resulLoanCheckAllowFetch: ', resulLoanCheckAllowFetch);
        setIsAllowedCreateLoan(resulLoanCheckAllowFetch.isAllowed);
      } catch (error) {
        console.log('geLoanCheckAllowFetch | error: ', error);
      }
    }

    geBankAccountCheckAllow();
    geLoanCheckAllowFetch();
  }, [navigation]);

  async function exit(event) {
    event.preventDefault();

    try {
      logout();
      navigate('/', { replace: true });
    } catch (error) {
      console.log('exit | error', error);
    }
  }

  function hideNavLinks() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }

  return (
    <div className="topnav" id="myTopnav">
      <a href="/home" className="active">
        Agro FAN
        {` v${version}`}
      </a>
      <NavLink to="/solicitations">
        Solicitações
      </NavLink>
      <NavLink to="/news">
        Noticias
      </NavLink>
      <NavLink to="/store">
        Loja
      </NavLink>
      <NavLink to="/properties">
        Propriedades
      </NavLink>
      <NavLink to="/fanducaju">
        Fanducaju
      </NavLink>
      <NavLink to="/production">
        Produção
      </NavLink>
      {isAllowedEditInput
        ? (
          <NavLink to="/bank-account">
            Banco
          </NavLink>
        ) : null}
      {isAllowedCreateLoan
        ? (
          <NavLink NavLink to="/financial">
            Antecipação
          </NavLink>
        ) : null}
      <NavLink to="/profile">
        Perfil
      </NavLink>
      <NavLink to="/notebook">
        Caderno
      </NavLink>
      <NavLink to="/quality">
        Qualidade
      </NavLink>
      <NavLink to="/soil">
        Solo
      </NavLink>
      <a
        href="https://api.whatsapp.com/send?phone=5585992903907"
        target="_blank"
        rel="noreferrer"
        // onClick={() => { window.location.href = 'https://api.whatsapp.com/send?phone=5585992903907'; }}
      >
        SAC
      </a>
      <a
        href="#B"
        onClick={(event) => exit(event)}
      >
        Sair
      </a>
      <a
        href="javascript:void(0);"
        style={{ fontSize: 15 }}
        className="icon"
        onClick={() => hideNavLinks()}
      >
        &#9776;
      </a>
    </div>
  );
}

export default NavBar;
