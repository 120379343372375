/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from 'react';
import './styles.css';

export default function ModalNewTermUse({
  onAction,
  onRead,
}) {
  return (
    <div className="modal">
      <div className="container">
        <div className="content">
          <div className="content__top">
            <p className="title__modal">
              TERMOS DE USO ATUALIZADO
            </p>
            <p className="level__description">
              Leia e aceite o novo termos de uso para continuar utilizando nosso sistema.
            </p>
            <button
              className="form__button"
              type="button"
              // eslint-disable-next-line no-undef
              onClick={() => onAction(true)}
            >
              ACEITO
            </button>
            <button
              className="form__button"
              type="button"
              // eslint-disable-next-line no-undef
              onClick={() => onRead()}
            >
              LER TERMOS DE USO
            </button>
            <button
              className="form__button"
              type="button"
              // eslint-disable-next-line no-undef
              onClick={() => onAction(false)}
            >
              NÃO ACEITO
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
