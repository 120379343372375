/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import {
  ItemsFetch,
  CategoriesFetch,
  ItemsByNameOrCategoryFetch,
  DemandsByUserIdFetch,
} from '../../api/services/StoreAPI';

import { addMoneyMask } from '../../utils/masks/manipulateMoneyMask';
import { getToken } from '../../api/auth';

export default function Store({ history }) {
  const ordinances = [
    { id: 0, name: 'sem ordenação' },
    { id: 1, name: 'ordenação alfabética de A - Z' },
    { id: 2, name: 'ordenação alfabética de Z - A' },
  ];

  const token = getToken();

  const [demands, setDemands] = useState([]);
  const [countDemands, setCountDemands] = useState(0);
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ordinationSelected, setOrdinationSelected] = useState(ordinances[0]);
  const [categorySelected, setCategorySelected] = useState({ id: 0, name: 'sem filtro por categoria' });
  const [searchName, setSearchName] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function getCategories() {
    setIsLoad(true);
    try {
      const resultStoreCategories = await CategoriesFetch();
      console.log('getCategories | resultStoreCategories: ', resultStoreCategories);
      setIsLoad(false);
      setMessageWarning(resultStoreCategories.message);
      setIsSuccessMessage(resultStoreCategories.isSuccess);
      if (resultStoreCategories.isSuccess) {
        setCategories(resultStoreCategories.categories);
      }
    } catch (error) {
      console.log('getCategories | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getItems(orderOption) {
    console.log('getItems | orderOption: ', orderOption);
    setIsLoad(true);
    try {
      const resultStore = await ItemsFetch(orderOption);
      console.log('getItems | resultStore: ', resultStore);
      setIsLoad(false);
      setMessageWarning(resultStore.message);
      setIsSuccessMessage(resultStore.isSuccess);
      if (resultStore.isSuccess) {
        setItems(resultStore.items);
      }
    } catch (error) {
      console.log('getItems | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getItemsByNameOrCategory(itemName, categoryId, orderOption) {
    setIsLoad(true);
    try {
      const resultItemsByNameOrCategory = await ItemsByNameOrCategoryFetch(itemName, categoryId, orderOption);
      console.log('getItemsByNameOrCategory | resultItemsByNameOrCategory: ', resultItemsByNameOrCategory);
      setIsLoad(false);
      setMessageWarning(resultItemsByNameOrCategory.message);
      setIsSuccessMessage(resultItemsByNameOrCategory.isSuccess);
      if (resultItemsByNameOrCategory.isSuccess) {
        setItems(resultItemsByNameOrCategory.items);
      }
    } catch (error) {
      console.log('getItemsByNameOrCategory | error: ', error);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getDemands() {
    setIsLoad(true);
    try {
      const resultDemands = await DemandsByUserIdFetch(token);
      console.log('getDemands | resultDemands: ', resultDemands);
      setIsLoad(false);
      setMessageWarning(resultDemands.message);
      setIsSuccessMessage(resultDemands.isSuccess);
      if (resultDemands.isSuccess) {
        setDemands(resultDemands.demands);
      }
    } catch (error) {
      console.log('getDemands | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  function getItemsOptions(optionSelected = ordinationSelected.id) {
    console.log('getItemsOptions | optionSelected, searchName, categorySelected.id', optionSelected, searchName, categorySelected.id);
    if (searchName !== '' || categorySelected.id !== 0) {
      getItemsByNameOrCategory(searchName, categorySelected.id, optionSelected);
    } else {
      getItems(optionSelected);
    }
  }

  function refreshLoad() {
    getCategories();
    getDemands(token);
    getItemsOptions();
  }

  useEffect(() => {
    refreshLoad();
  }, [history]);

  useEffect(() => {
    const countDemandsNotConcluded = demands.map(
      (demandLoop) => !demandLoop.order_concluded && demandLoop.status !== 'recusado',
    );
    console.log('Routes | countDemandsNotConcluded: ', countDemandsNotConcluded);
    const sumDemandsNotConcluded = countDemandsNotConcluded.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0,
    );
    console.log('Routes | sumDemandsNotConcluded: ', sumDemandsNotConcluded);
    setCountDemands(sumDemandsNotConcluded);
  }, [demands]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Loja
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <button
            className="form__button"
            type="button"
            onClick={() => navigate('/demand', { replace: true })}
          >
            Ir para pedidos
            {countDemands >= 1 ? ` (${countDemands})` : ''}
          </button>

          <input
            className="form__input"
            type="text"
            placeholder="digite o nome do produto"
            value={searchName}
            onChange={(event) => setSearchName(event.target.value)}
          />

          <button
            className="form__button"
            type="button"
            onClick={(event) => getItemsByNameOrCategory(searchName, categorySelected.id, ordinationSelected.id)}
          >
            Pesquisar
          </button>

          <div className="label__container">
            <p className="form__label">
              Filtro por Categoria
            </p>
          </div>
          <select
            className="form__select"
            value={categorySelected.name}
            onChange={(event) => {
              console.log(event.target.value);
              function isCategory(category) {
                return category.name === event.target.value;
              }
              const categoryFound = categories.find(isCategory);
              console.log('categoryFound: ', event.target.value);
              setCategorySelected(categoryFound);
              getItemsByNameOrCategory(searchName, categoryFound.id, ordinationSelected.id);
            }}
          >
            {
              categories.map((category) => (
                <option
                  value={category.name}
                  key={category.name}
                >
                  {category.name}
                </option>
              ))
            }
          </select>

          <div className="label__container">
            <p className="form__label">
              Ordenação por
            </p>
          </div>
          <select
            name="ordinances"
            className="form__select"
            value={ordinationSelected.name}
            onChange={(event) => {
              console.log('select | city | event.target.value: ', event.target.value);
              function isOrdinance(category) {
                return category.name === event.target.value;
              }
              const ordinationFound = ordinances.find(isOrdinance);
              setOrdinationSelected(ordinationFound);
              getItemsOptions(ordinationFound.id);
            }}
          >
            {
              ordinances.map((ordination) => (
                <option value={ordination.name} key={ordination.name}>
                  {ordination.name}
                </option>
              ))
            }
          </select>

          {items.map((item) => (
            <div
              className="news__container"
              onClick={() => navigate(`/store/${item.id}`, { replace: true })}
              aria-hidden="true"
            >
              <img src={item.image} alt="newsImage" width="100%" height="170" style={{ objectFit: 'contain' }} />
              <p className="titleItems__value">
                {(item.name).toUpperCase()}
              </p>
              <p className="price__value">
                {addMoneyMask(item.price)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
