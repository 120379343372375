/* eslint-disable no-console */

import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export default async function SoilAnalysisFetch(tokenAuthorization) {
  console.log('SoilAnalysisFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/analysis/analysis_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('SoilAnalysisFetch | response.data.qualities: ', response.data.analysis);
    return {
      isSuccess: true,
      message: '',
      soilAnalysis: response.data.analysis,
    };
  } catch (error) {
    console.log('SoilAnalysisFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      soilAnalysis: null,
    };
  }
}
