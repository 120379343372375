/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

export default function QualityDetailCard({
  qualitySelectedParam,
  onAction,
}) {
  function calculeTotalImpurities() {
    const cajuiFloat = parseFloat(qualitySelectedParam.tabPercentualImpuL1);
    const impurityFloat = parseFloat(qualitySelectedParam.tabPercentualImpuL2);
    const stuckFloat = parseFloat(qualitySelectedParam.tabPercentualImpuL3);
    const cochasFloat = parseFloat(qualitySelectedParam.tabPercentualImpuL4);
    const spoiledFloat = parseFloat(qualitySelectedParam.tabPercentualImpuL5);
    const totalImpurities = cajuiFloat + impurityFloat + stuckFloat + cochasFloat + spoiledFloat;
    const totalImpuritiesTwoDecimalPlaces = totalImpurities.toFixed(2);

    return `${totalImpuritiesTwoDecimalPlaces}%`;
  }

  return (
    <>
      <button
        className="form__button--outline"
        type="button"
        onClick={() => onAction()}
      >
        Voltar
      </button>
      <div className="details--container">
        <p className="details--text details--text-title">
          Data do Laudo
        </p>
        <p className="details--text details--text-description">
          {qualitySelectedParam.dataDoLaudo}
        </p>

        <p className="details--text details--text-title">
          Fornecedor
        </p>
        <p className="details--text details--text-description">
          {qualitySelectedParam.fornecLaudo}
        </p>

        <div className="production--container-row">
          <p className="details--text details--text-title">
            Cajuí
          </p>
          <p className="details--text details--text-description">
            {`${qualitySelectedParam.tabPercentualImpuL1}%`}
          </p>
        </div>

        <div className="production--container-row">
          <p className="details--text details--text-title">
            Impureza
          </p>
          <p className="details--text details--text-description">
            {`${qualitySelectedParam.tabPercentualImpuL2}%`}
          </p>
        </div>

        <div className="production--container-row">
          <p className="details--text details--text-title">
            Furada
          </p>
          <p className="details--text details--text-description">
            {`${qualitySelectedParam.tabPercentualImpuL3}%`}
          </p>
        </div>

        <div className="production--container-row">
          <p className="details--text details--text-title">
            Cochas
          </p>
          <p className="details--text details--text-description">
            {`${qualitySelectedParam.tabPercentualImpuL4}%`}
          </p>
        </div>

        <div className="production--container-row">
          <p className="details--text details--text-title">
            Estragadas
          </p>
          <p className="details--text details--text-description">
            {`${qualitySelectedParam.tabPercentualImpuL5}%`}
          </p>
        </div>

        <div className="production--container-row">
          <p className="details--text details--text-title">
            Total de Rejeitos
          </p>
          <p className="details--text details--text-description">
            {calculeTotalImpurities()}
          </p>
        </div>

        <div className="production--container-row">
          <div className="quality--container-column">
            <p className="details--text details--text-title">
              Umidade
            </p>
            <p className="details--text details--text-description">
              {`${qualitySelectedParam.totalPercSementes}%`}
            </p>
          </div>
          <div className="quality--container-column">
            <p className="details--text details--text-title">
              Semente por KG
            </p>
            <p className="details--text details--text-description">
              {qualitySelectedParam.totalSememtes}
            </p>
          </div>
        </div>

        <div className="production--container-row">
          <div className="quality--container-column">
            <p className="details--text details--text-title">
              Rendimento
            </p>
            <p className="details--text details--text-description">
              {`${qualitySelectedParam.tabPercentualRendimentoBraL2}%`}
            </p>
          </div>
          <div className="quality--container-column">
            <p className="details--text details--text-title">
              Avaliação
            </p>
            <p className="details--text details--text-description">
              {qualitySelectedParam.opcoes}
            </p>
          </div>
        </div>
      </div>

    </>
  );
}
