/* eslint-disable no-console */
import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export async function LoanCheckAllowFetch(tokenAuthorization) {
  console.log('LoanCheckAllowFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/loans/check_loan_permission',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('LoanCheckAllowFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: '',
      isAllowed: response.data.has_permission,
    };
  } catch (error) {
    console.log('LoanCheckAllowFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401') {
      console.log('LoanCheckAllowFetch | error.response.data:', error.response.data);
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.headers.headers,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
      isAllowed: false,
    };
  }
}

export async function FinancialsFetch(tokenAuthorization) {
  console.log('FinancialsFetch | tokenAuthorization:', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/loans/loans_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('FinancialsFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      loans: response.data.loans,
    };
  } catch (error) {
    console.log('FinancialsFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers.headers,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      loans: [],
    };
  }
}

export async function FinancialsCreateFetch(tokenAuthorization, loanValue) {
  console.log(
    'FinancialsCreateFetch | tokenAuthorization, loanValue: ',
    tokenAuthorization,
    loanValue,
  );
  const configRequest = {
    method: 'post',
    url: '/loans/create_loan',
    body: { value: loanValue },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('FinancialsCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );

    console.log('FinancialsCreateFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('FinancialsCreateFetch | error: ', error);
    console.log('FinancialsCreateFetch | error.message: ', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 409') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        {
          ...configRequest.headers.headers,
          ...configRequest.body,
        },
      );
    }
    return {
      isSuccess: false,
      message,
      financials: null,
    };
  }
}
