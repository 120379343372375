import createExceptionSentry from '../../utils/createExceptionSentry';

import Axios from '../Axios';

export async function TermOfUseFetch() {
  console.log('TermOfUseFetch');

  const configRequest = {
    method: 'get',
    url: '/term_of_use/get_term_of_use',
  };

  try {
    const response = await Axios[configRequest.method](configRequest.url);
    console.log('TermOfUseFetch | response.data ', response.data);

    return {
      isSuccess: true,
      message: '',
      termOfUse: response.data.terms.terms_of_use,
    };
  } catch (error) {
    console.log('TermOfUseFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      termOfUse: '',
    };
  }
}

export async function NewTermsUseAvailableFetch(tokenAuthorization) {
  console.log('NewTermsUseAvailableFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/term_of_use/new_term_of_use',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('NewTermsUseAvailableFetch | response.data:', response.data);
    const isAvailable = response.data.new;
    return {
      isSuccess: true,
      message: '',
      isAvailable,
      termOfUse: isAvailable ? response.data.term : null,
    };
  } catch (error) {
    console.log('NewTermsUseAvailableFetch | error:', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      isAvailable: false,
      termOfUse: null,
    };
  }
}

export async function UpdateAcceptedTermUseFetch(
  tokenAuthorization,
  termUseId,
  isAccepted,
) {
  console.log(
    'UpdateAcceptedTermUseFetch | tokenAuthorization, termUseId, isAccepted,: ',
    tokenAuthorization,
    termUseId,
    isAccepted,
  );
  const configRequest = {
    method: 'post',
    url: '/term_of_use/accepted_term_of_use',
    body: { term_id: termUseId, accepted: isAccepted },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('UpdateAcceptedTermUseFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: '',
    };
  } catch (error) {
    console.log('UpdateAcceptedTermUseFetch | error:', error.message);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}
