/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-empty-pattern */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from 'react';

export default function BenefitsTable({ benefitsParam }) {
  console.log('BenefitsTable | benefitsParam: ', benefitsParam);

  return (
    <>
      {benefitsParam.map((benefit, index) => (
        <div className="container_benefits">
          <div className="benefits_border" />
          <p className="benefits_description">{benefit}</p>
          {(index + 1) === benefitsParam.length ? (
            <div className="benefits_border" />
          ) : null}
        </div>
      ))}
    </>
  );
}
