/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarWithHourAndMinutesMask from '../../utils/masks/calendarWithHourAndMinutesMask';

import SoilAnalysisFetch from '../../api/services/SoilAPI';

export default function Soil({ history }) {
  const token = getToken();

  const [soilAnalysis, setSoilAnalysis] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    async function getSoilAnalysis() {
      setIsLoad(true);
      try {
        const resultSolicitations = await SoilAnalysisFetch(token);
        console.log('getSoilAnalysis | resultSolicitations: ', resultSolicitations);
        setIsLoad(false);
        setMessageWarning(resultSolicitations.message);
        setIsSuccessMessage(resultSolicitations.isSuccess);
        if (resultSolicitations.isSuccess) {
          setSoilAnalysis(resultSolicitations.soilAnalysis);
        }
      } catch (error) {
        console.log('getSoilAnalysis | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getSoilAnalysis();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Histórico de Análise do Solo
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {soilAnalysis.map((solicitation) => (
            <div className="detail__container">
              <p className="detail__value">
                {`#${solicitation.id}`}
              </p>
              <p className="detail__value">
                {solicitation.observation}
              </p>
              <p className="detail__value">
                {(calendarWithHourAndMinutesMask(solicitation.created)).toUpperCase()}
              </p>

              <button
                className="button--report"
                type="button"
                onClick={() => { window.location.href = solicitation.document; }}
              >
                Visualizar Relatório
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
